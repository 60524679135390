import PlugsResolver from '@components/plugs/PlugResolver';
import { usePreviewSubscription } from '@utils/sanity/sanity';
import { getClient, sanityClient } from '@utils/sanity/sanity.server';
import { GetStaticPaths, GetStaticProps } from 'next';
import { FunctionComponent } from 'react';

import EmptyPage from '../components/EmptyPage';
import globalQuery from '../utils/queries/globalQuery';
import routePathsQuery from '../utils/queries/routePathsQuery';
import routeQuery from '../utils/queries/routeQuery';

type Props = {
  data?: {
    slug;
    content;
  };
  preview?: string;
};

const Index: FunctionComponent<Props> = ({ data = { slug: '', content: {} }, preview = null }) => {
  const slug = data?.slug?.current;
  let content = [];
  const { data: subscribedData } = usePreviewSubscription(routeQuery, {
    params: { slug },
    initialData: data,
    enabled: !!preview,
  });

  if (subscribedData?.content) {
    content = subscribedData.content;
  } else {
    content = data.content;
  }

  return <>{content ? <PlugsResolver plugs={content} /> : <EmptyPage text="Empty page" />}</>;
};

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const data = await sanityClient.fetch(routePathsQuery);

  const paths = [];

  locales.forEach((locale, i) => {
    data.forEach((route, j) => {
      const u = route?.slug?.current?.toString();
      paths.push({ params: { id: i * data.length + j, slug: [u !== '/' ? u : ''] }, locale });
    });
  });

  return { paths, fallback: false };
};

export const getStaticProps: GetStaticProps = async ({ params, locale, preview = null }) => {
  const { slug } = params;

  const sanitySlug = !slug ? '/' : Array.isArray(slug) ? slug[0] : '404';

  const data = await getClient(preview).fetch(routeQuery, { slug: sanitySlug, locale });
  const global = (await getClient(preview).fetch(globalQuery, { locale })) || { content: {} };

  return { props: { data, global, preview, sanitySlug }, revalidate: 600 };
};

export default Index;
